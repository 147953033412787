import { useEffect, useMemo, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { InferType, object, string } from 'yup';

import cardCourseRequestDialogButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_request_dialog_button_click';
import cardCourseRequestElementShown from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_request_element_shown';
import { translation, useSelector, format, TranslatedComponent } from '@hh.ru/front-static-app';
import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Input,
    Modal,
    NavigationBar,
    Title,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, CrossOutlinedSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import FormDisclaimer from 'src/components/FormDisclaimer';
import useFormValidation from 'src/hooks/useFormValidation';
import useSnackbar from 'src/hooks/useSnackbar';
import { CourseDto } from 'src/types/courses';
import { phoneFormatter } from 'src/utils/format/phoneFormatter';

import postRequestCourse from 'src/components/CardCourse/common/CardCourseRequest/api/postRequestCourse';

import styles from './styles.less';

interface CardCourseRequestDialogProps {
    course: CourseDto;
    visible: boolean;
    onClose: () => void;
}

const FORM_ID = 'course-request-form';

const TrlKeys = {
    title: 'career-platform.components.card-course.request-to-course.dialog.title',
    subtitle: 'career-platform.components.card-course.request-to-course.dialog.subtitle',
    form: {
        fullname: {
            placeholder: 'career-platform.components.card-course.request-to-course.dialog.fullname.placeholder',
            errors: {
                required: 'career-platform.components.card-course.request-to-course.dialog.fullname.required',
            },
        },
        phone: {
            placeholder: 'career-platform.components.card-course.request-to-course.dialog.phone.placeholder',
            errors: {
                required: 'career-platform.components.card-course.request-to-course.dialog.phone.required',
            },
        },
    },
    buttons: {
        send: 'career-platform.components.card-course.request-to-course.dialog.button.send',
        close: 'career-platform.components.card-course.request-to-course.dialog.button.close',
    },
    snack: {
        success: 'career-platform.components.card-course.request-to-course.snack.success',
        error: 'career-platform.components.card-course.request-to-course.snack.error',
    },
};

enum ValidationError {
    Required = 'required',
}

const validationSchema = object({
    fullname: string().default('').required(ValidationError.Required),
    phone: string()
        .default('+7')
        .required(ValidationError.Required)
        .matches(/\+7\d{10}/, ValidationError.Required),
});

type FormValues = InferType<typeof validationSchema>;

const CardCourseRequestDialog: TranslatedComponent<CardCourseRequestDialogProps> = ({
    visible,
    onClose,
    trls,
    course,
}) => {
    const { isXS, isS } = useBreakpoint();
    const isMobile = isXS || isS;
    const { showSnack, hideSnack } = useSnackbar();

    const ref = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (ref.current && visible) {
            cardCourseRequestElementShown(ref.current);
        }
    }, [visible]);

    const { onValidate, showErrorAfterFirstSubmit } = useFormValidation(validationSchema);

    const handleButtonClose = () => {
        cardCourseRequestDialogButtonClick({ buttonName: 'card_course_request_close' });
        onClose();
    };

    const handleSubmit = async (values: FormValues) => {
        try {
            await postRequestCourse(course.id, values);

            showSnack({
                children: format(trls[TrlKeys.snack.success], {
                    '{0}': phoneFormatter('', values.phone, 0).formattedValue,
                }),
                addon: <CheckCircleFilledSize24 initial="positive" />,
                onClose: hideSnack,
                showClose: true,
            });

            cardCourseRequestDialogButtonClick({
                buttonName: 'card_course_request_send',
                fullname: values.fullname,
                phone: values.phone,
            });

            onClose();
        } catch (err) {
            showSnack({
                children: trls[TrlKeys.snack.error],
                addon: <MinusCircleFilledSize24 initial="negative" />,
                onClose: hideSnack,
                showClose: true,
            });
        }
    };

    const fullname = useSelector((state) => state.profile?.fullName || '');
    const phone = useSelector((state) => state.profile?.phone || '');

    const initialValues: FormValues = useMemo(() => {
        const schemaDefaults = validationSchema.getDefault();

        return { ...schemaDefaults, fullname, phone: phone || schemaDefaults.phone };
    }, [fullname, phone]);

    const renderForm = () => (
        <Form<FormValues> initialValues={initialValues} validate={onValidate} onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
                <form className={styles.form} ref={ref} id={FORM_ID} onSubmit={handleSubmit}>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <Field<FormValues['fullname']> name="fullname">
                                {({ input, meta }) => (
                                    <Input
                                        {...input}
                                        size="large"
                                        maxLength={70}
                                        placeholder={trls[TrlKeys.form.fullname.placeholder]}
                                        elevatePlaceholder
                                        invalid={showErrorAfterFirstSubmit(meta)}
                                        errorMessage={
                                            meta.error
                                                ? trls[TrlKeys.form.fullname.errors[meta.error as 'required']]
                                                : undefined
                                        }
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.field}>
                            <Field<FormValues['phone']> name="phone">
                                {({ input, meta }) => (
                                    <Input
                                        {...input}
                                        size="large"
                                        placeholder={trls[TrlKeys.form.phone.placeholder]}
                                        formatter={phoneFormatter}
                                        maxLength={16}
                                        elevatePlaceholder
                                        invalid={showErrorAfterFirstSubmit(meta)}
                                        errorMessage={
                                            meta.error
                                                ? trls[TrlKeys.form.phone.errors[meta.error as 'required']]
                                                : undefined
                                        }
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className={styles.aggreementLabel}>
                        <FormDisclaimer buttonName={trls[TrlKeys.buttons.send]} />
                    </div>
                </form>
            )}
        </Form>
    );

    return (
        <>
            {isMobile && (
                <BottomSheet
                    visible={visible}
                    onClose={handleButtonClose}
                    header={
                        <NavigationBar
                            right={<Action icon={CrossOutlinedSize24} onClick={handleButtonClose} />}
                            options={
                                <Title size="medium" description={trls[TrlKeys.subtitle]} Element="h3">
                                    {trls[TrlKeys.title]}
                                </Title>
                            }
                        />
                    }
                >
                    <>
                        {renderForm()}
                        <BottomSheetFooter>
                            <Button type="submit" disabled={!visible} form={FORM_ID} mode="primary">
                                {trls[TrlKeys.buttons.send]}
                            </Button>
                        </BottomSheetFooter>
                    </>
                </BottomSheet>
            )}

            {!isMobile && (
                <Modal
                    title={trls[TrlKeys.title]}
                    titleDescription={trls[TrlKeys.subtitle]}
                    visible={visible}
                    onClose={handleButtonClose}
                    footer={
                        <ActionBar
                            secondaryActions={
                                <Button mode="tertiary" onClick={handleButtonClose}>
                                    {trls[TrlKeys.buttons.close]}
                                </Button>
                            }
                            primaryActions={
                                <Button type="submit" disabled={!visible} form={FORM_ID} mode="primary">
                                    {trls[TrlKeys.buttons.send]}
                                </Button>
                            }
                        />
                    }
                >
                    {renderForm()}
                </Modal>
            )}
        </>
    );
};

export default translation(CardCourseRequestDialog);
