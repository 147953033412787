import cardCourseButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import { CourseTypeFollowLink } from 'src/types/courses';
import { analytics, Goal } from 'src/utils/analytics';
import openNewBlankTab from 'src/utils/openNewBlankTab';

const TrlKeys = {
    button: 'career-platform.components.button-link-external-course.button',
};

interface CardCourseButtonLinkExternalProps {
    course: CourseTypeFollowLink;
}

export const cardCourseButtonLinkExternalHandler = (course: CourseTypeFollowLink): void => {
    // так обходим блокировку контента со стороны adBlock,
    // abBlock большинство ссылок от партнеров считает рекламными и скрывает за display: none
    openNewBlankTab(course.link);
    analytics.reach(Goal.click_profession_guide_go_to_course);
    cardCourseButtonClick({
        courseName: course.name,
        providerId: course?.provider?.id || null,
        providerName: course?.provider?.name || null,
        cardType: course.purpose,
        professionId: course.profession?.id || null,
        coursePrice: course.price?.fullAmount || course.price?.amountPerMonth || 0,
    });
};

const CardCourseButtonLinkExternal: TranslatedComponent<CardCourseButtonLinkExternalProps> = ({ course, trls }) => (
    <Button style="neutral" mode="primary" stretched onClick={() => cardCourseButtonLinkExternalHandler(course)}>
        {trls[TrlKeys.button]}
    </Button>
);

export default translation(CardCourseButtonLinkExternal);
