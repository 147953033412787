import { useEffect, useRef, useState } from 'react';

import cardCourseElementShown from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_element_shown';
import cardCourseRequestDialogOpenButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_request_dialog_open_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Card, Tag, useBreakpoint } from '@hh.ru/magritte-ui';

import CardCourseBreadcrumbsInfo from 'src/components/CardCourse/common/CardCourseBreadcrumbsInfo';
import CardCourseButtonLinkExternal, {
    cardCourseButtonLinkExternalHandler,
} from 'src/components/CardCourse/common/CardCourseButtonLinkExternal';
import CardCourseLogoPromoCompany from 'src/components/CardCourse/common/CardCourseLogoPromoCompany';
import CardCoursePrice from 'src/components/CardCourse/common/CardCoursePrice';
import CardCoursePromocode from 'src/components/CardCourse/common/CardCoursePromocode';
import CardCourseRequest from 'src/components/CardCourse/common/CardCourseRequest';
import CardCourseSubscribe from 'src/components/CardCourse/common/CardCourseSubscribe';
import {
    CourseDto,
    CourseFormat,
    isCourseTypeFollowLink,
    isCourseTypeRequest,
    isCourseTypeSubscribe,
} from 'src/types/courses';
import getDataQa from 'src/utils/getDataQa';
import getKebabCase from 'src/utils/getKebabCase';
import pluralize from 'src/utils/pluralize';

import styles from './styles.less';

interface CardCourseProps {
    course: CourseDto;
}

const TrlKeys = {
    breadcrumbs: {
        profession: 'career-platform.components.card-course.breadcrumbs.profession',
        skill: 'career-platform.components.card-course.breadcrumbs.skill',
        format: {
            [CourseFormat.OFFLINE]: 'career-platform.components.card-course.breadcrumbs.format.offline',
        },
    },
    course: {
        development: 'career-platform.components.card-course.development',
    },
    date: {
        SECOND: {
            one: 'career-platform.pluralize.second.one',
            some: 'career-platform.pluralize.second.some',
            many: 'career-platform.pluralize.second.many',
        },
        MINUTE: {
            one: 'career-platform.pluralize.minute.one',
            some: 'career-platform.pluralize.minute.some',
            many: 'career-platform.pluralize.minute.many',
        },
        HOURS: {
            one: 'career-platform.pluralize.hours.one',
            some: 'career-platform.pluralize.hours.some',
            many: 'career-platform.pluralize.hours.many',
        },
        DAYS: {
            one: 'career-platform.pluralize.days.one',
            some: 'career-platform.pluralize.days.some',
            many: 'career-platform.pluralize.days.many',
        },
        WEEKS: {
            one: 'career-platform.pluralize.weeks.one',
            some: 'career-platform.pluralize.weeks.some',
            many: 'career-platform.pluralize.weeks.many',
        },
        MONTHS: {
            one: 'career-platform.pluralize.months.one',
            some: 'career-platform.pluralize.months.some',
            many: 'career-platform.pluralize.months.many',
        },
        YEARS: {
            one: 'career-platform.pluralize.years.one',
            some: 'career-platform.pluralize.years.some',
            many: 'career-platform.pluralize.years.many',
        },
    },
    button: 'career-platform.components.button-link-external-course.button',
};

enum ActiveDialog {
    SubscribeToCourse = 'SubscribeToCourse',
    RequestToCourse = 'RequestToCourse',
}

const CardCourse: TranslatedComponent<CardCourseProps> = ({ course, trls }) => {
    const { isMobile } = useBreakpoint();
    const ref = useRef<HTMLDivElement>(null);

    const [activeDialog, setActiveDialog] = useState<ActiveDialog>();

    const handleClickSubscribe = () => {
        setActiveDialog(ActiveDialog.SubscribeToCourse);
    };

    const handleClickRequest = () => {
        setActiveDialog(ActiveDialog.RequestToCourse);
        cardCourseRequestDialogOpenButtonClick({
            courseName: course.name,
            professionId: course.profession?.id || null,
            skillId: course.skill?.id || null,
            providerName: course.provider?.name || null,
            cardType: course.purpose,
        });
    };

    const getBreadcrumbsInfo = () => {
        return [
            course.format === CourseFormat.OFFLINE && trls[TrlKeys.breadcrumbs.format[CourseFormat.OFFLINE]],
            course.level && [course.level.from, course.level.to].filter(Boolean).join('→'),
            course.duration &&
                pluralize({
                    count: course.duration.value,
                    one: trls[TrlKeys.date[course.duration.type].one],
                    some: trls[TrlKeys.date[course.duration.type].some],
                    many: trls[TrlKeys.date[course.duration.type].many],
                    useCount: true,
                }),
            course.stream.name,
        ].filter(Boolean) as string[];
    };

    useEffect(() => {
        if (ref.current) {
            cardCourseElementShown(ref.current, {
                courseName: course.name,
                providerId: course?.provider?.id || null,
                providerName: course?.provider?.name || null,
                cardType: course.purpose,
                professionId: course.profession?.id || null,
            });
        }
    }, [course]);

    const handleCardClick = () => {
        // ignore text selection
        if (window.getSelection()?.toString().length) {
            return;
        }
        if (isCourseTypeFollowLink(course)) {
            cardCourseButtonLinkExternalHandler(course);
            return;
        }
        if (isCourseTypeSubscribe(course)) {
            handleClickSubscribe();
            return;
        }
        if (isCourseTypeRequest(course)) {
            handleClickRequest();
        }
    };

    return (
        <>
            <Card
                ref={ref}
                onClick={handleCardClick}
                actionCard
                padding={isMobile ? 16 : 24}
                borderRadius={isMobile ? 16 : 24}
                borderBottomLeftRadius={24}
                borderBottomRightRadius={24}
                showBorder={!course.style}
                stretched
                verticalStretched
                // course.style == null считается тоже цветной карточкой и игнорит showBorder
                style={course.style ? course.style : undefined}
                data-qa={getDataQa('course', course.id, getKebabCase(course.purpose))}
            >
                <div className={styles.content}>
                    <div className={styles.breadcrumbs}>
                        <CardCourseBreadcrumbsInfo items={getBreadcrumbsInfo()} />
                    </div>
                    <h5 className={styles.title} data-qa={getDataQa('name')}>
                        {course.name}
                    </h5>
                    {course.provider && (
                        <div className={styles.provider}>
                            <CardCourseLogoPromoCompany name={course.provider.name} iconSrc={course.provider.icon} />
                        </div>
                    )}
                    <div className={styles.bottomContent}>
                        {isCourseTypeFollowLink(course) && (
                            <>
                                {course?.price?.promotion && (
                                    <div className={styles.promocode}>
                                        <CardCoursePromocode
                                            discount={course.price.promotion.discount}
                                            code={course.price.promotion.code}
                                            course={course}
                                        />
                                    </div>
                                )}
                                {course.price && (
                                    <div className={styles.price}>
                                        <CardCoursePrice
                                            fullAmount={course.price.fullAmount}
                                            discountAmount={course.price.discountAmount}
                                            amountPerMonth={course.price.amountPerMonth}
                                            currency={course.price.currency}
                                        />
                                    </div>
                                )}
                                <CardCourseButtonLinkExternal course={course} />
                            </>
                        )}
                        {isCourseTypeSubscribe(course) && (
                            <>
                                <div className={styles.tagCourseDeveloped}>
                                    <Tag style="neutral">{trls[TrlKeys.course.development]}</Tag>
                                </div>
                                <CardCourseSubscribe.Button onClick={handleClickSubscribe} />
                            </>
                        )}
                        {isCourseTypeRequest(course) && <CardCourseRequest.Button onClick={handleClickRequest} />}
                    </div>
                </div>
            </Card>
            <CardCourseRequest.Dialog
                course={course}
                visible={activeDialog === ActiveDialog.RequestToCourse}
                onClose={() => setActiveDialog(undefined)}
            />
            <CardCourseSubscribe.Dialog
                isVisible={activeDialog === ActiveDialog.SubscribeToCourse}
                onClose={() => setActiveDialog(undefined)}
                dialogSubtitleText={course.name}
                dialogDescriptionText={course.description}
                skillId={course.skill?.id}
                professionId={course.profession?.id}
                analyticsParams={{
                    professionId: course.profession?.id,
                    courseName: course?.name,
                    skillId: course.skill?.id,
                    skillName: course.skill?.name,
                    providerId: course?.provider?.id,
                    providerName: course?.provider?.name,
                }}
            />
        </>
    );
};

export default translation(CardCourse);
